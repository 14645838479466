import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../components/layout';

const ContactPage = () => (
	<Layout>
		<Helmet>
			<title>Goods Br - Contato</title>
			<meta property="og:title" content="Goods Br - Contato" />
		</Helmet>
		<div className="contact">
			<div className="container">
				<h1 className="contact-title" style={{ fontSize: 0, margin: 0, lineHeight: 0 }}>
					Fale Conosco
				</h1>
				<div className="contact-content">
					<div className="contact-form w-form">
						<form
							id="email-form"
							name="email-form"
							method="post"
							action="/"
							data-name="Email Form"
							className="w-clearfix"
							data-netlify="true"
							data-netlify-honeypot="bot-field"
						>
							<input type="hidden" name="bot-field" />
							<label for="email-2">Nome</label>
							<input
								type="text"
								className="field w-input"
								maxlength="256"
								name="name"
								data-name="Name"
								placeholder="Digite aqui o seu nome"
								id="name"
							/>
							<label for="email">E-mail</label>
							<input
								type="email"
								className="field w-input"
								maxlength="256"
								name="email"
								data-name="Email"
								placeholder="Digite aqui o seu e-mail"
								id="email"
								required=""
							/>
							<label for="message">Mensagem</label>
							<textarea
								id="message"
								name="message"
								placeholder="Digite aqui a sua mensagem"
								maxlength="5000"
								required=""
								data-name="message"
								className="field w-input"
							/>
							<input
								type="submit"
								value="Enviar Mensagem"
								data-wait="Please wait..."
								className="button form w-button"
							/>
						</form>
					</div>
					<div className="contact-info-content">
						<p className="contact-p">
							Ligue para a Goods Br no número
							<br />
							<span className="contact-phone">(54) 3295-5300</span>
						</p>
						<p className="contact-p">
							Envie um e-mail para
							<br />
							<span className="contact-phone">contato@goodsbr.com.br</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
);

export default ContactPage;
